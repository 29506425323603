import { apiUrl } from "@/env";
import axios from "axios";

export const authApi = {
  async logIn(authCode: string) {
    const headers = {
      "X-Requested-With": "XMLHttpRequest",
    };
    return await axios.post(`${apiUrl}/api/auth/swap-token`, authCode, {
      headers: headers,
    });
  },
  async refreshToken() {
    const headers = {
      "X-Requested-With": "XMLHttpRequest",
    };
    return await axios.post(`${apiUrl}/api/auth/refresh-token`, null, {
      headers: headers,
    });
  },
  async logOut() {
    return await axios.get(`${apiUrl}/api/auth/logout`);
  },
  async getMe() {
    return axios.get(`${apiUrl}/api/auth/users/me`);
  },
};
