import {
  AccountTeam,
  Category,
  ChoiceModel,
  PodcastGenre,
  Team,
} from "@/interfaces";

import { apiUrl } from "@/env";
import axios from "axios";

export const choicesApi = {
  async listTeams() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<Team[]>(`${apiUrl}/api/choices/teams/list`, { params });
  },
  async listCrews() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(`${apiUrl}/api/choices/crews/list`, {
      params,
    });
  },
  async listTiers() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(`${apiUrl}/api/choices/tiers/list`, {
      params,
    });
  },
  async listContentFormats() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(
      `${apiUrl}/api/choices/content_formats/list`,
      {
        params,
      }
    );
  },
  async listContentSources() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(
      `${apiUrl}/api/choices/content_sources/list`,
      {
        params,
      }
    );
  },
  async listClasses() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(`${apiUrl}/api/choices/classes/list`, {
      params,
    });
  },
  async listManagerRoles() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(`${apiUrl}/api/choices/roles/list`, {
      params,
    });
  },
  async listContractStatuses() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(
      `${apiUrl}/api/choices/contract_statuses/list`,
      { params }
    );
  },
  async listNetworks() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(`${apiUrl}/api/choices/networks/list`, {
      params,
    });
  },
  async listNetworkStatuses() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(
      `${apiUrl}/api/choices/network_statuses/list`,
      { params }
    );
  },
  async listCategories() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<Category[]>(`${apiUrl}/api/choices/categories/list`, {
      params,
    });
  },
  async listRights() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(`${apiUrl}/api/choices/rights/list`, {
      params,
    });
  },
  async listPodcastStatuses() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<ChoiceModel[]>(
      `${apiUrl}/api/choices/podcast_statuses/list`,
      {
        params,
      }
    );
  },
  async listPodcastGenres() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<PodcastGenre[]>(
      `${apiUrl}/api/choices/podcast_genres/list`,
      {
        params,
      }
    );
  },
  async listChinaPlatformNetworkStatuses() {
    return axios.get<ChoiceModel[]>(
      `${apiUrl}/api/choices/china_platform_network_statuses/list`
    );
  },
  async listAccountTeams() {
    return axios.get<AccountTeam[]>(`${apiUrl}/api/choices/account_teams/list`);
  },
};
