import { Manager, UserActivity } from "@/interfaces";

import { apiUrl } from "@/env";
import axios from "axios";

export default {
  async listUserActivities(
    limit: number,
    startDate: string = null,
    endDate: string = null
  ) {
    const params = new URLSearchParams();
    params.append("limit", limit.toString());
    if (startDate && endDate) {
      params.append("start_date", startDate);
      params.append("end_date", endDate);
    }
    return axios.get<UserActivity[]>(
      `${apiUrl}/api/utils/list-user-activities`,
      { params }
    );
  },
  async createUserActivity(createData: object) {
    return axios.post<UserActivity>(
      `${apiUrl}/api/utils/record-user-activity`,
      createData
    );
  },
  async updateManager(managerId: number, updateData: object) {
    const params = new URLSearchParams();
    params.append("manager_id", managerId.toString());
    return axios.put<Manager>(`${apiUrl}/api/managers/update`, updateData, {
      params,
    });
  },
  async deleteManager(managerId: number) {
    const params = new URLSearchParams();
    params.append("manager_id", managerId.toString());
    return axios.delete<Manager>(`${apiUrl}/api/managers/delete`, { params });
  },
  async createManager(createData: object) {
    return axios.post<Manager>(`${apiUrl}/api/managers/create`, createData);
  },
  async listManagers() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    // let skip = (page - 1) * perPage;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<Manager[]>(`${apiUrl}/api/managers/list`, { params });
  },
  async listManagersByCreator(creatorId: number) {
    const params = new URLSearchParams();
    params.append("creator_id", creatorId.toString());
    return axios.get<Manager[]>(`${apiUrl}/api/managers/list-by-creator`, {
      params,
    });
  },
  async getManager(id: number, byDashboardId: boolean = false) {
    const params = new URLSearchParams();
    if (byDashboardId) {
      params.append("dashboard_id", id.toString());
    } else {
      params.append("manager_id", id.toString());
    }
    return axios.get<Manager>(`${apiUrl}/api/managers/get`, { params });
  },
  async getManagerByEmail(email: string) {
    const params = new URLSearchParams();
    params.append("email", email.toString().toLowerCase());
    return axios.get<Manager>(`${apiUrl}/api/managers/get-by-email`, {
      params,
    });
  },
  async updateOnboardProgress(tour: string) {
    return axios.post<Manager>(
      `${apiUrl}/api/managers/update-onboard-progress`,
      {
        tour,
      }
    );
  },
};
