









import { api } from "@/api";
import { appVer } from "@/env";
import { logOut, refreshToken } from "@/helpers/auth";
import { store } from "@/store";
import { dispatchGetMe } from "@/store/auth/actions";
import { dispatchSetNavigationVisible } from "@/store/main/actions";
import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { readIsManager } from "@/store/auth/getters";
import router from "./router";
import "@/assets/styles/global.scss";

const startRouteGuard = async function (to, from, next) {
  if (to.path == "/login") {
    return next();
  }
  const response = await dispatchGetMe(store);
  if (response.status == 200) {
    // navigate if authenticated
    if (to.path.includes("create") || to.path.includes("import")) {
      if (!readIsManager(store)) {
        return next("/");
      }
    }
    return next();
  } else {
    // refresh token otherwise
    const res = await refreshToken();
    if (res.status == 200) {
      const r = await dispatchGetMe(store);
      if (r.status == 200) {
        return next();
      } else {
        await logOut();
        return next(`/login?to=${to.path}`);
      }
    } else {
      await logOut();
      return next(`/login?to=${to.path}`);
    }
  }
};

const afterRouteGuard = async function (to, from) {
  await dispatchSetNavigationVisible(store, false);
};

router.beforeEach((to, from, next) => startRouteGuard(to, from, next));
router.afterEach((to, from) => afterRouteGuard(to, from));

axios.interceptors.response.use(null, async (error) => {
  if (error.response) {
    const url = error.config.url;
    const urlParts = url.split("/").filter((item) => item);
    const pathEnd = urlParts.pop();
    // skip these api paths to avoid infinite loops
    if (["refresh-token", "swap-token", "me", "logout"].includes(pathEnd)) {
      return error.response;
    }

    const currentPath = router.currentRoute.path;

    // attempt to refresh token if unauthenticated
    if (error.response.status === 401) {
      // don't attempt refresh on the second try
      if (error.config._retry) {
        // logout and go to login page
        await logOut();
        if (currentPath !== "/login") {
          return await router.push(`/login?to=${currentPath}`);
        }
      } else {
        // refresh token
        const res = await refreshToken();
        if (res.status == 200) {
          // if refreshed: retry request
          error.config._retry = true;
          return axios.request(error.config);
        } else {
          // if failed: logout and go to login page
          await logOut();
          if (currentPath !== "/login") {
            return await router.push(`/login?to=${currentPath}`);
          }
        }
      }
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    return Promise.reject(error);
  }
});

@Component
export default class App extends Vue {
  public async beforeCreate() {
    console.log(`Welcome to Omni version ${appVer}`);
    const response = await api.checkAppVer(appVer);
    if (response) {
      if (!response.data) {
        window.location.reload();
      }
    }
    try {
      this["$notification"].requestPermission().then(console.log);
    } catch (e) {}
  }
}
