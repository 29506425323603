import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { CreatorState } from "./state";

export const creatorState: CreatorState = {
  creator: {
    id: null,
    first_name: null,
    marketing_allowed: null,
    termination_scheduled: null,
    created_at: null,
    updated_at: null,
  },
  mappingGroupId: null,
  accounts: [],
};

export const creatorModule = {
  state: creatorState,
  mutations,
  actions,
  getters,
};
