import Vue from "vue";
import Router from "vue-router";

const originalPush = Router.prototype.push;
// @ts-ignore
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/Login.vue"),
    },
    {
      path: "/",
      component: () =>
        import(/* webpackChunkName: "main" */ "./views/Main.vue"),
      redirect: "/overview",
      children: [
        {
          path: "overview",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "./views/overview/Overview.vue"
            ),
        },
        {
          path: "manage",
          component: () =>
            import(
              /* webpackChunkName: "manage" */ "./views/manage/Manage.vue"
            ),
          redirect: "manage/managers",
          children: [
            {
              path: "managers",
              component: () =>
                import(
                  /* webpackChunkName: "manage-managers" */ "./views/manage/manager/Managers.vue"
                ),
            },
            {
              path: "managers/create",
              component: () =>
                import(
                  /* webpackChunkName: "manage-managers-create" */ "./views/manage/manager/ManagerCreate.vue"
                ),
            },
            {
              path: "managers/:id",
              component: () =>
                import(
                  /* webpackChunkName: "manage-manager" */ "./views/manage/manager/Manager.vue"
                ),
            },
            {
              path: "mapping-groups",
              component: () =>
                import(
                  /* webpackChunkName: "manage-mapping-groups" */ "./views/manage/mapping-group/MappingGroups.vue"
                ),
            },
            // {
            //   path: "mapping-groups/create",
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "manage-mapping-groups-create" */ "./views/manage/mapping-group/MappingGroupCreate.vue"
            //     ),
            // },
            // {
            //   path: "mapping-groups/:id",
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "manage-mapping-group" */ "./views/manage/mapping-group/MappingGroup.vue"
            //     ),
            // },
            {
              path: "creators",
              component: () =>
                import(
                  /* webpackChunkName: "manage-creators" */ "./views/manage/creator/Creators.vue"
                ),
            },
            {
              path: "creators/import",
              component: () =>
                import(
                  /* webpackChunkName: "manage-creators" */ "./views/manage/creator/Creators.vue"
                ),
            },
            {
              path: "creators/create",
              component: () =>
                import(
                  /* webpackChunkName: "manage-creators-create" */ "./views/manage/creator/CreatorCreate.vue"
                ),
            },
            {
              path: "creators/:id",
              component: () =>
                import(
                  /* webpackChunkName: "manage-creator" */ "./views/manage/creator/Creator.vue"
                ),
            },
          ],
        },
        {
          path: "mastersheet",
          component: () =>
            import(
              /* webpackChunkName: "mastersheet" */ "./views/mastersheet/Mastersheet.vue"
            ),
          redirect: "/mastersheet/youtube",
          children: [
            {
              path: "clip-licensing",
              component: () =>
                import(
                  /* webpackChunkName: "mastersheet" */ "./views/mastersheet/Mastersheet.vue"
                ),
              meta: {
                selectedAccountType: "Clip Licensing",
              },
            },
            {
              path: "youtube",
              component: () =>
                import(
                  /* webpackChunkName: "mastersheet" */ "./views/mastersheet/Mastersheet.vue"
                ),
              meta: {
                selectedAccountType: "YouTube Channel",
              },
            },
            {
              path: "podcast",
              component: () =>
                import(
                  /* webpackChunkName: "mastersheet" */ "./views/mastersheet/Mastersheet.vue"
                ),
              meta: {
                selectedAccountType: "Podcast",
              },
            },
            {
              path: "china-platform",
              component: () =>
                import(
                  /* webpackChunkName: "mastersheet" */ "./views/mastersheet/Mastersheet.vue"
                ),
              meta: {
                selectedAccountType: "China Platform",
              },
            },
            {
              path: "create",
              meta: {
                displayMaximizable: true,
                createView: true,
              },
              component: () =>
                import(
                  /* webpackChunkName: "mastersheet-edit" */ "./views/mastersheet/MastersheetEdit.vue"
                ),
            },
            {
              path: ":id",
              meta: {
                displayMaximizable: true,
                createView: false,
              },
              component: () =>
                import(
                  /* webpackChunkName: "mastersheet-edit" */ "./views/mastersheet/MastersheetEdit.vue"
                ),
            },
          ],
        },
        {
          path: "help",
          component: () =>
            import(/* webpackChunkName: "help" */ "./views/help/Help.vue"),
          redirect: "help/introductions",
          children: [
            {
              path: "introductions",
              component: () =>
                import(
                  /* webpackChunkName: "introductions" */ "./views/help/faq/Introductions.vue"
                ),
            },
            {
              path: "guide",
              component: () =>
                import(
                  /* webpackChunkName: "guide" */ "./views/help/faq/Guide.vue"
                ),
            },
            {
              path: "onboarding",
              component: () =>
                import(
                  /* webpackChunkName: "onboarding" */ "./views/help/faq/Onboarding.vue"
                ),
            },
            {
              path: "roles",
              component: () =>
                import(
                  /* webpackChunkName: "user-roles" */ "./views/help/faq/UserRoles.vue"
                ),
            },
            {
              path: "contact",
              component: () =>
                import(
                  /* webpackChunkName: "contact" */ "./views/help/faq/Contact.vue"
                ),
            },
          ],
        },
      ],
    },
    {
      path: "/analytics/:type",
      name: "Analytics",
      component: () =>
        import(/* webpackChunkName: "analytics" */ "./views/Analytics.vue"),
    },
    {
      path: "/analytics/",
      name: "Analytics",
      redirect: "/analytics/accounts",
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const element = document.querySelector(to.hash) as HTMLElement;
      if (element) {
        return window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    } else {
      return { x: 0, y: 0 };
    }
  },
});
