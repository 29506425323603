import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MappingGroupState } from "./state";

export const mappingGroupState: MappingGroupState = {
  mappingGroup: {
    id: 0,
    name: "",
    manager_count: 0,
    updated_at: "",
    description: "",
    members: [],
  },
  creatorsByMappingGroup: [],
};

export const mappingGroupModule = {
  state: mappingGroupState,
  mutations,
  actions,
  getters,
};
