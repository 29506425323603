import { api } from "@/api";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import { commitSetMe } from "./mutations";
import { AuthState } from "./state";
import { logIn, logOut, refreshToken } from "@/helpers/auth";
import router from "@/router";

type AuthContext = ActionContext<AuthState, State>;

export const actions = {
  async actionLogIn(
    context: AuthContext,
    payload: { authCode: string; to: string }
  ) {
    const response = await logIn(payload.authCode);
    if (response.status == 200) {
      const res = await dispatchGetMe(context);
      if (res.status === 401 || res.status === 500) {
        throw new Error(res.data.detail || "Unknown error occurred");
      }
      await router.push(payload.to);
    } else if (response.status == 401) {
      const res = await refreshToken();
      if (res.status == 200) {
        await dispatchGetMe(context);
      } else {
        await logOut();
      }
    } else {
      await logOut();
    }

    if (response.status === 403 || response.status === 500) {
      throw new Error(response.data.detail || "Unknown error occurred");
    }
    return response;
  },
  async actionGetMe(context: AuthContext) {
    const response = await api.getMe();
    if (response.status === 200 && response.data) {
      commitSetMe(context, response.data);
    }
    return response;
  },
};

const { dispatch } = getStoreAccessors<AuthState | any, State>("");

export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchGetMe = dispatch(actions.actionGetMe);
