import { MappingGroupState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  mappingGroup: (state: MappingGroupState) => {
    const mappingGroup = state.mappingGroup;
    if (mappingGroup) {
      return { ...mappingGroup };
    }
  },
  creatorsByMappingGroup: (state: MappingGroupState) =>
    state.creatorsByMappingGroup
};

const { read } = getStoreAccessors<MappingGroupState, State>("");

export const readMappingGroup = read(getters.mappingGroup);
export const readCreatorsByMappingGroup = read(getters.creatorsByMappingGroup);
