import { AccountState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  accountFull: (state: AccountState) => {
    return state.accountFull;
  },
  searchCreatorFuse: (state: AccountState) => {
    return state.searchCreatorFuse;
  },
};

const { read } = getStoreAccessors<AccountState, State>("");

export const readAccountFull = read(getters.accountFull);
export const readSearchCreatorFuse = read(getters.searchCreatorFuse);
