import { api } from "@/api";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitSetCategories,
  commitSetClasses,
  commitSetContractStatuses,
  commitSetCountries,
  commitSetCreators,
  commitSetCrews,
  commitSetGeneralFuse,
  commitSetLanguages,
  commitSetManagerRoles,
  commitSetManagers,
  commitSetMappingGroups,
  commitSetNavigationVisible,
  commitSetNetworks,
  commitSetNetworkStatuses,
  commitSetChinaPlatformNetworkStatuses,
  commitSetOffices,
  commitSetAccountTeams,
  commitSetRights,
  commitSetSearchObjects,
  commitSetTeams,
  commitSetTiers,
  commitSetContentFormats,
  commitSetContentSources,
  commitSetNotificationSidebarVisible,
  commitSetUserUnread,
  commitSetSystemUnread,
  commitSetUserNotifications,
  commitSetSystemNotifications,
  commitSetPodcastStatuses,
  commitSetPodcastGenres,
} from "./mutations";
import { MainState } from "./state";
import Fuse from "fuse.js";
import dayjs from "dayjs";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  actionSetNotificationSidebarVisible(context: MainContext, visible: boolean) {
    commitSetNotificationSidebarVisible(context, visible);
  },
  actionSetNavigationVisible(context: MainContext, visible: boolean) {
    commitSetNavigationVisible(context, visible);
  },
  async actionListManagers(context: MainContext) {
    const response = await api.listManagers();
    if (response) {
      commitSetManagers(context, response.data);
    }
  },
  async actionListMappingGroups(context: MainContext) {
    const response = await api.listMappingGroups();
    if (response) {
      commitSetMappingGroups(context, response.data);
    }
  },
  async actionListCreators(context: MainContext) {
    const response = await api.listCreators();
    if (response) {
      commitSetCreators(context, response.data);
    }
  },
  async actionListOffices(context: MainContext) {
    const response = await api.listOffices();
    if (response) {
      commitSetOffices(context, response.data);
    }
  },
  async actionListAccountTeams(context: MainContext) {
    const response = await api.listAccountTeams();
    if (response) {
      commitSetAccountTeams(context, response.data);
    }
  },
  async actionListCountries(context: MainContext) {
    const response = await api.listCountries();
    if (response) {
      commitSetCountries(context, response.data);
    }
  },
  async actionListLanguages(context: MainContext) {
    const response = await api.listLanguages();
    if (response) {
      commitSetLanguages(context, response.data);
    }
  },
  async actionListTeams(context: MainContext) {
    const response = await api.listTeams();
    if (response) {
      commitSetTeams(context, response.data);
    }
  },
  async actionListContentFormats(context: MainContext) {
    const response = await api.listContentFormats();
    if (response) {
      commitSetContentFormats(context, response.data);
    }
  },
  async actionListContentSources(context: MainContext) {
    const response = await api.listContentSources();
    if (response) {
      commitSetContentSources(context, response.data);
    }
  },
  async actionListCrews(context: MainContext) {
    const response = await api.listCrews();
    if (response) {
      commitSetCrews(context, response.data);
    }
  },
  async actionListTiers(context: MainContext) {
    const response = await api.listTiers();
    if (response) {
      commitSetTiers(context, response.data);
    }
  },
  async actionListClasses(context: MainContext) {
    const response = await api.listClasses();
    if (response) {
      commitSetClasses(context, response.data);
    }
  },
  async actionListManagerRoles(context: MainContext) {
    const response = await api.listManagerRoles();
    if (response) {
      commitSetManagerRoles(context, response.data);
    }
  },
  async actionListContractStatuses(context: MainContext) {
    const response = await api.listContractStatuses();
    if (response) {
      commitSetContractStatuses(context, response.data);
    }
  },
  async actionListSearchObjects(context: MainContext) {
    const response = await api.listSearchObjects();
    let finalResponse = [];
    if (response) {
      for (const i in response) {
        finalResponse = finalResponse.concat(response[i].data);
      }
      commitSetSearchObjects(context, finalResponse);
    }
  },
  async actionGetGeneralFuse(context: MainContext, payload) {
    const options = {
      threshold: 0.2,
      findAllMatches: false,
      keys: [
        "full_name",
        "email",
        "nickname",
        "dashboard_id",
        "youtube_channel_id",
        "custom_id",
        "channel_name",
        "name",
        "description",
        "manager_email",
      ],
    };
    commitSetGeneralFuse(context, new Fuse(payload, options));
  },
  async actionListNetworks(context: MainContext) {
    const response = await api.listNetworks();
    if (response) {
      commitSetNetworks(context, response.data);
    }
  },
  async actionListNetworkStatuses(context: MainContext) {
    const response = await api.listNetworkStatuses();
    if (response) {
      commitSetNetworkStatuses(context, response.data);
    }
  },
  async actionListChinaPlatformNetworkStatuses(context: MainContext) {
    const response = await api.listChinaPlatformNetworkStatuses();
    if (response) {
      commitSetChinaPlatformNetworkStatuses(context, response.data);
    }
  },
  async actionListCategories(context: MainContext) {
    const response = await api.listCategories();
    if (response) {
      commitSetCategories(context, response.data);
    }
  },
  async actionListRights(context: MainContext) {
    const response = await api.listRights();
    if (response) {
      commitSetRights(context, response.data);
    }
  },
  async actionListPodcastStatuses(context: MainContext) {
    const response = await api.listPodcastStatuses();
    if (response) {
      commitSetPodcastStatuses(context, response.data);
    }
  },
  async actionListPodcastGenres(context: MainContext) {
    const response = await api.listPodcastGenres();
    if (response) {
      commitSetPodcastGenres(context, response.data);
    }
  },
  async actionListUserNotifications(
    context: MainContext,
    payload: { read: boolean }
  ) {
    const startDate = dayjs()
      .subtract(1, "month")
      .format("YYYY-MM-DD")
      .toString();
    const response = await api.listNotifications(
      startDate,
      false,
      payload.read
    );
    if (response) {
      commitSetUserNotifications(context, response.data);
    }
  },
  async actionListSystemNotifications(
    context: MainContext,
    payload: { read: boolean }
  ) {
    const startDate = dayjs()
      .subtract(1, "month")
      .format("YYYY-MM-DD")
      .toString();
    const response = await api.listNotifications(startDate, true, payload.read);
    if (response) {
      commitSetSystemNotifications(context, response.data);
    }
  },
  async actionGetUnreadCount(context: MainContext) {
    const response = await api.readUnreadCount();
    if (response) {
      const userUnread = response.data.filter((item) => item.system == false);
      commitSetUserUnread(
        context,
        userUnread.length ? userUnread[0].unread_count : 0
      );
      const systemUnread = response.data.filter((item) => item.system == true);
      commitSetSystemUnread(
        context,
        systemUnread.length ? systemUnread[0].unread_count : 0
      );
    }
  },
};

const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchSetNotificationSidebarVisible = dispatch(
  actions.actionSetNotificationSidebarVisible
);
export const dispatchSetNavigationVisible = dispatch(
  actions.actionSetNavigationVisible
);
export const dispatchListUserNotifications = dispatch(
  actions.actionListUserNotifications
);
export const dispatchListSystemNotifications = dispatch(
  actions.actionListSystemNotifications
);
export const dispatchGetUnreadCount = dispatch(actions.actionGetUnreadCount);

export const dispatchListManagers = dispatch(actions.actionListManagers);
export const dispatchListMappingGroups = dispatch(
  actions.actionListMappingGroups
);
export const dispatchListCreators = dispatch(actions.actionListCreators);
export const dispatchListOffices = dispatch(actions.actionListOffices);
export const dispatchListAccountTeams = dispatch(
  actions.actionListAccountTeams
);
export const dispatchListCountries = dispatch(actions.actionListCountries);
export const dispatchListTeams = dispatch(actions.actionListTeams);
export const dispatchListManagerRoles = dispatch(
  actions.actionListManagerRoles
);
export const dispatchListContractStatuses = dispatch(
  actions.actionListContractStatuses
);
export const dispatchListLanguages = dispatch(actions.actionListLanguages);
export const dispatchListCrews = dispatch(actions.actionListCrews);
export const dispatchListSearchObjects = dispatch(
  actions.actionListSearchObjects
);
export const dispatchGetGeneralFuse = dispatch(actions.actionGetGeneralFuse);
export const dispatchListTiers = dispatch(actions.actionListTiers);
export const dispatchListClasses = dispatch(actions.actionListClasses);
export const dispatchListNetworks = dispatch(actions.actionListNetworks);
export const dispatchListNetworkStatuses = dispatch(
  actions.actionListNetworkStatuses
);
export const dispatchListChinaPlatformNetworkStatuses = dispatch(
  actions.actionListChinaPlatformNetworkStatuses
);
export const dispatchListCategories = dispatch(actions.actionListCategories);
export const dispatchListRights = dispatch(actions.actionListRights);
export const dispatchListContentFormats = dispatch(
  actions.actionListContentFormats
);
export const dispatchListContentSources = dispatch(
  actions.actionListContentSources
);
export const dispatchListPodcastStatuses = dispatch(
  actions.actionListPodcastStatuses
);
export const dispatchListPodcastGenres = dispatch(
  actions.actionListPodcastGenres
);
