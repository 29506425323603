import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AccountState } from "./state";

export const accountState: AccountState = {
  accountFull: {
    id: null,
    creator_id: null,
    contract_status_id: null,
    tier_id: null,
    created_at: null,
    updated_at: null,
  },
  searchCreatorFuse: undefined,
};

export const accountModule = {
  state: accountState,
  mutations,
  actions,
  getters,
};
