import { AppNotification, UnreadCount } from "@/interfaces";

import { apiUrl } from "@/env";
import axios from "axios";

export const notificationsApi = {
  async listNotifications(startDate: string, system: boolean, read: boolean) {
    const params = new URLSearchParams();
    if (startDate) params.append("start_date", startDate.toString());
    params.append("system", system.toString());
    if (read) params.append("read", read.toString());
    return axios.get<AppNotification[]>(
      `${apiUrl}/api/notifications/list-by-manager`,
      { params }
    );
  },
  async readUnreadCount() {
    return axios.get<UnreadCount[]>(
      `${apiUrl}/api/notifications/get-unread-count-by-manager`
    );
  },
  async readNotification(notificationId: number) {
    const params = new URLSearchParams();
    params.append("notification_id", notificationId.toString());
    return axios.post<AppNotification>(
      `${apiUrl}/api/notifications/mark-as-read`,
      {},
      { params }
    );
  },
  async readAllNotifications(system: boolean) {
    const params = new URLSearchParams();
    params.append("system", system.toString());
    return axios.post(
      `${apiUrl}/api/notifications/mark-all-as-read`,
      {},
      { params }
    );
  },
  async createUserNotification(userId: number, notificationType: string) {
    const params = new URLSearchParams();
    params.append("user_id", userId.toString());
    params.append(
      "notification_type",
      notificationType.toString().toLowerCase()
    );
    return axios.post<AppNotification>(
      `${apiUrl}/api/notifications/create-user-notification`,
      {},
      { params }
    );
  },
  async createSystemNotificationToAll(payload: object) {
    return axios.post<AppNotification>(
      `${apiUrl}/api/notifications/create-system-notification-to-all`,
      payload
    );
  },
};
