import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MainState } from "./state";

const defaultState: MainState = {
  notificationSidebarVisible: false,
  navigationVisible: false,
  userNotifications: [],
  systemNotifications: [],
  systemUnread: 0,
  userUnread: 0,
  managers: [],
  mappingGroups: [],
  creators: [],
  offices: [],
  accountTeams: [],
  countries: [],
  languages: [],
  teams: [],
  crews: [],
  managerRoles: [],
  contractStatuses: [],
  searchObjects: [],
  generalFuse: undefined,
  tiers: [],
  networks: [],
  networkStatuses: [],
  chinaPlatformNetworkStatuses: [],
  categories: [],
  rights: [],
  classes: [],
  contentFormats: [],
  contentSources: [],
  podcastStatuses: [],
  podcastGenres: [],
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
