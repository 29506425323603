import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from "vee-validate";
import {
  email,
  integer,
  max_value,
  min_value,
  numeric,
  oneOf,
  regex,
  required,
} from "vee-validate/dist/rules";

import Vue from "vue";

setInteractionMode("eager");
extend("email", {
  ...email,
  message: "Please provide a valid email.",
});
extend("url", {
  ...regex,
  message: "Please provide a valid url.",
});
extend("integer", {
  ...integer,
  message: "This field needs to be an integer.",
});
extend("numeric", {
  ...numeric,
  message: "This field needs to be numeric.",
});
extend("max_value", {
  ...max_value,
  message: "This field exceeds the maximum value.",
});
extend("min_value", {
  ...min_value,
  message: "This field needs to be positive.",
});
extend("required", {
  ...required,
  message: "This field is required.",
});
extend("oneOf", {
  ...oneOf,
  message: "This is not an existing ID.",
});
extend("youtube", {
  ...regex,
  message: "YouTube Channel ID should be 24 character-long and starts with UC.",
});
extend("date", {
  ...regex,
  message: "Date needs to be in the format of YYYY-MM-DD.",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
