import { Creator } from "@/interfaces";
import { apiUrl } from "@/env";
import axios from "axios";

export const creatorsApi = {
  async updateCreator(creatorId: number, updateData: object) {
    const params = new URLSearchParams();
    params.append("creator_id", creatorId.toString());
    return axios.put<Creator>(`${apiUrl}/api/creators/update`, updateData, {
      params,
    });
  },
  async deleteCreator(creatorId: number) {
    const params = new URLSearchParams();
    params.append("creator_id", creatorId.toString());
    return axios.delete<Creator>(`${apiUrl}/api/creators/delete`, { params });
  },
  async createCreator(createData: object) {
    return axios.post<Creator>(`${apiUrl}/api/creators/create`, createData);
  },
  async listCreators(skip: number = 0, limit: number = 0) {
    const params = new URLSearchParams();
    params.append("skip", skip.toString());
    params.append("limit", limit.toString());
    return axios.get<Creator[]>(`${apiUrl}/api/creators/list`, { params });
  },
  async getCreator(creatorId: number) {
    const params = new URLSearchParams();
    params.append("creator_id", creatorId.toString());
    return axios.get<Creator>(`${apiUrl}/api/creators/get`, { params });
  },
  async importCreators(creatorType: string, file: File) {
    const formData = new FormData();
    formData.append("creator_type", creatorType);
    formData.append("file", file);
    return axios.post(`${apiUrl}/api/creators/import`, formData);
  },
};
