import { ManagerState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  manager: (state: ManagerState) => {
    const manager = state.manager;
    if (manager) {
      return { ...manager };
    }
  },
  mappingGroupsByManager: (state: ManagerState) => state.mappingGroupsByManager,
  creatorsByManager: (state: ManagerState) => state.creatorsByManager
};

const { read } = getStoreAccessors<ManagerState, State>("");

export const readManager = read(getters.manager);
export const readMappingGroupsByManager = read(getters.mappingGroupsByManager);
export const readCreatorsByManager = read(getters.creatorsByManager);
