import { api } from "@/api";
import { ActionContext } from "vuex";
import { State } from "../state";
import { MappingGroupState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { commitSetMappingGroup, commitUnsetMappingGroup } from "./mutations";

type MainContext = ActionContext<MappingGroupState, State>;

export const actions = {
  async actionGetMappingGroup(context: MainContext, payload: { id: number }) {
    const response = await api.getMappingGroup(payload.id);
    if (response) {
      commitSetMappingGroup(context, response.data);
    }
  },
  async actionUpdateMappingGroup(
    context: MainContext,
    payload: { id: number; data: object }
  ) {
    const response = await api.updateMappingGroup(payload.id, payload.data);
    if (response && response.status === 200) {
      commitSetMappingGroup(context, response.data);
      return response.data;
    }
  },
  async actionDeleteMappingGroup(
    context: MainContext,
    payload: { id: number }
  ) {
    const response = await api.deleteMappingGroup(payload.id);
    commitUnsetMappingGroup(context);
    return response;
  },
  async actionClearMappingGroup(context: MainContext) {
    commitUnsetMappingGroup(context);
  },
  async actionCreateMappingGroup(
    context: MainContext,
    payload: { data: object }
  ) {
    const response = await api.createMappingGroup(payload.data);
    if (response.status === 200) {
      commitSetMappingGroup(context, response.data);
      return response.data;
    }
  },
};

const { dispatch } = getStoreAccessors<MappingGroupState, State>("");

export const dispatchGetMappingGroup = dispatch(actions.actionGetMappingGroup);
export const dispatchDeleteMappingGroup = dispatch(
  actions.actionDeleteMappingGroup
);
export const dispatchClearMappingGroup = dispatch(
  actions.actionClearMappingGroup
);
export const dispatchUpdateMappingGroup = dispatch(
  actions.actionUpdateMappingGroup
);
export const dispatchCreateMappingGroup = dispatch(
  actions.actionCreateMappingGroup
);
