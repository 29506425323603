




















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  dispatchGetUnreadCount,
  dispatchListSystemNotifications,
  dispatchListUserNotifications,
  dispatchSetNotificationSidebarVisible,
} from "@/store/main/actions";
import {
  readNotificationSidebarVisible,
  readSystemNotifications,
  readSystemUnread,
  readUserNotifications,
  readUserUnread,
} from "@/store/main/getters";

import { AppNotification } from "@/interfaces";
import VueMarkdown from "vue-markdown";
import { api } from "@/api";
import dayjs from "dayjs";
import { readMe } from "@/store/auth/getters";

const relativeTime = require("dayjs/plugin/relativeTime");
const utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
dayjs.extend(utc);

@Component({
  components: {
    VueMarkdown,
  },
})
export default class NotificationClass extends Vue {
  public filter = "All";
  public filterOptions = ["All", "Unread", "Read"];

  get sidebarVisible() {
    return readNotificationSidebarVisible(this.$store);
  }

  set sidebarVisible(value: boolean) {
    if (!value) {
      dispatchGetUnreadCount(this.$store);
      dispatchListUserNotifications(this.$store, { read: null });
      dispatchListSystemNotifications(this.$store, { read: null });
    }
    dispatchSetNotificationSidebarVisible(this.$store, value);
  }

  get userNotificationsRead() {
    const r = readUserNotifications(this.$store);
    return r.filter((item) => item.read);
  }

  get userNotificationUnread() {
    const r = readUserNotifications(this.$store);
    return r.filter((item) => !item.read);
  }

  get systemNotifications() {
    return readSystemNotifications(this.$store);
  }

  get systemNotificationsRead() {
    return this.systemNotifications.filter((item) => item.read);
  }

  get systemNotificationsUnread() {
    return this.systemNotifications.filter((item) => !item.read);
  }

  get userUnread() {
    return readUserUnread(this.$store);
  }

  get systemUnread() {
    return readSystemUnread(this.$store);
  }

  get totalUnread() {
    return this.userUnread + this.systemUnread;
  }

  get user() {
    return readMe(this.$store);
  }

  public setTimeDisplay(timestamp: Date) {
    const now = dayjs().valueOf();
    // fix precision issue with dayjs by adding Z suffix
    const time = dayjs(timestamp + "Z");
    if (now - time.unix() < 3600000) {
      return time["fromNow"]();
    }
    return time.format("YYYY-MM-DD [at] HH:mm");
  }

  public getContentDisplay(item: AppNotification) {
    switch (item.data.type) {
      case "mention":
        return `**${item.data.author_name}** mentioned you in a comment on the ${item.data.root_type} **${item.data.root_name}**`;
      case "comment":
        return `**${item.data.author_name}** commented on your ${item.data.root_type} **${item.data.root_name}**`;
      case "automatic_update":
        return `Your ${item.data.root_type} **${item.data.root_name}** has been updated by the system.`;
    }
  }

  public getLink(item: AppNotification) {
    if (item.data.type === "announcement") {
      return "/overview#announcements";
    }

    switch (item.data.root_type) {
      case "account":
        return `/mastersheet/${item.data.root_id}`;
      case "creator":
        return `/manage/creators/${item.data.root_id}`;
    }
  }

  public async readNotification(notificationId: number) {
    await api.readNotification(notificationId);
    dispatchGetUnreadCount(this.$store);
    dispatchListUserNotifications(this.$store, { read: null });
    dispatchListSystemNotifications(this.$store, { read: null });
  }

  public async readAllNotifications() {
    const isSystem = this.$refs.tabview["d_activeIndex"] === 1;
    const response = await api.readAllNotifications(isSystem);
    if (response && response.status === 200) {
      this["$toast"].add({
        severity: "success",
        detail: `Successfully marked all ${
          isSystem ? "system" : "user"
        } notifications as read.`,
        life: 20000,
      });
    }
  }
}
