import {
  AccountFull,
  Creator,
  MappingGroupMember,
  MappingGroupMemberInfo,
} from "@/interfaces";
import { CreatorState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { creatorState } from "@/store/creator";

export const mutations = {
  setCreator(state: CreatorState, payload: Creator) {
    state.creator = payload;
  },
  setMappingGroupId(state: CreatorState, payload: number) {
    state.mappingGroupId = payload;
  },
  unsetCreator(state: CreatorState) {
    state.creator = {
      id: null,
      first_name: null,
      marketing_allowed: null,
      termination_scheduled: null,
      created_at: null,
      updated_at: null,
    };
    state.mappingGroupId = creatorState.mappingGroupId;
  },
  setAccounts(state: CreatorState, payload: AccountFull[]) {
    state.accounts = payload;
  },
};

const { commit } = getStoreAccessors<CreatorState, State>("");

export const commitSetCreator = commit(mutations.setCreator);
export const commitSetMappingGroupId = commit(mutations.setMappingGroupId);
export const commitUnsetCreator = commit(mutations.unsetCreator);
export const commitSetAccounts = commit(mutations.setAccounts);
