import {
  Announcement,
  Contract,
  Country,
  Language,
  Office,
} from "@/interfaces";

import { apiUrl } from "@/env";
import axios from "axios";

axios.defaults.withCredentials = true;

export const firstApi = {
  async listOffices() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<Office[]>(`${apiUrl}/api/choices/offices/list`, {
      params,
    });
  },
  async listCountries() {
    const response = await axios.get("/resources/countries.json");
    response.data = response.data.map((country) => {
      return {
        name: country.name,
        alpha2Code: country.iso2,
      };
    });
    return response;
  },
  async listLanguages() {
    return axios.get<Language[]>(`${apiUrl}/api/utils/list-languages`);
  },
  async listContracts(
    status: string,
    expiringIn: number = 0,
    limit: number = 25,
    sort: string = "asc"
  ) {
    const params = new URLSearchParams();
    params.append("status", status);
    params.append("expiring_in", expiringIn.toString());
    params.append("limit", limit.toString());
    params.append("sort", sort);
    return axios.get<Contract[]>(
      `${apiUrl}/api/accounts/list-by-contract-status`,
      { params }
    );
  },
  async listSearchCreators() {
    return axios.get(`${apiUrl}/api/utils/list-search-creators`);
  },
  async listSearchManagers() {
    return axios.get(`${apiUrl}/api/utils/list-search-managers`);
  },
  async listSearchAccounts() {
    return axios.get(`${apiUrl}/api/utils/list-search-accounts`);
  },
  async listSearchObjects() {
    return axios.all([
      firstApi.listSearchCreators(),
      firstApi.listSearchManagers(),
      firstApi.listSearchAccounts(),
    ]);
  },
  async listRecentAnnouncements(limit: number) {
    const params = new URLSearchParams();
    params.append("limit", limit.toString());
    return axios.get<Announcement[]>(
      `${apiUrl}/api/notes/announcements/list-recent`,
      { params }
    );
  },
  async createAnnouncement(createData: object) {
    return axios.post<Announcement>(
      `${apiUrl}/api/notes/announcements/create`,
      createData
    );
  },
  async deleteAnnouncement(id: number) {
    return axios.delete<Announcement>(
      `${apiUrl}/api/notes/announcements/${id}/delete`
    );
  },
  async exportPage(entity: string, ids: number[], fields: string[] = []) {
    const params = new URLSearchParams();
    params.append("entity", entity.toString());
    const config = {
      params: params,
      ResponseType: "blob",
    };
    return axios.post(
      `${apiUrl}/api/utils/export`,
      {
        ids,
        fields,
      },
      config
    );
  },
  async submitContactForm(formData: object) {
    return axios.post(`${apiUrl}/api/utils/submit-contact-form`, formData);
  },
  async overviewInsights() {
    return axios.get(`${apiUrl}/api/overview/insights`);
  },
  async overviewPrevInsights() {
    return axios.get(`${apiUrl}/api/overview/prev-insights`);
  },
  async overviewTopChannels(params: object) {
    return axios.get(`${apiUrl}/api/overview/top-channels`, { params });
  },
  async overviewMilestoneAwards() {
    return axios.get(`${apiUrl}/api/overview/milestone-awards`);
  },
  async overviewStats(officeId: number = undefined) {
    const params = officeId === undefined ? {} : { office_id: officeId };
    return axios.get(`${apiUrl}/api/overview/stats`, { params });
  },
  async checkAppVer(appVer: string) {
    const params = new URLSearchParams();
    params.append("version", appVer);
    return axios.get(`${apiUrl}/api/utils/check-app-ver`, { params });
  },
  async getMetabaseIframeUrl(type: string = "accounts") {
    return axios.get(`${apiUrl}/api/utils/get-metabase-iframe-url`, {
      params: { type },
    });
  },
  async getSboIframeUrl() {
    return axios.get(`${apiUrl}/api/utils/get-sbo-iframe-url`);
  },
};
