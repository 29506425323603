import { apiUrl } from "@/env";
import axios from "axios";

export const commentsApi = {
  async createComment(source: string, createData: object) {
    return axios.post(`${apiUrl}/api/notes/${source}-notes/create`, createData);
  },
  async updateComment(source: string, commentId: number, updateData: object) {
    return axios.put(
      `${apiUrl}/api/notes/${source}-notes/${commentId}/update`,
      updateData
    );
  },
  async listComments(source: string, rootId: number) {
    const params = new URLSearchParams();
    params.append(`${source}_id`, rootId.toString());
    return axios.get(`${apiUrl}/api/notes/${source}-notes/list`, { params });
  },
  async deleteComment(source: string, commentId: number) {
    return axios.delete(
      `${apiUrl}/api/notes/${source}-notes/${commentId}/delete`
    );
  },
};
