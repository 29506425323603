import { CreatorState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  creator: (state: CreatorState) => {
    return state.creator;
  },
  mappingGroupId: (state: CreatorState) => {
    return state.mappingGroupId;
  },
  accounts: (state: CreatorState) => {
    return state.accounts;
  },
};

const { read } = getStoreAccessors<CreatorState, State>("");

export const readCreator = read(getters.creator);
export const readMappingGroupId = read(getters.mappingGroupId);
export const readAccounts = read(getters.accounts);
