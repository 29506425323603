import * as workerTimers from "worker-timers";

import { api } from "@/api";

export async function logOut() {
  sessionStorage.clear();
  await api.logOut();
}

export async function refreshToken() {
  const response = await api.refreshToken();
  if (response.status == 200) {
    const timeout = response.data.at_exp * 1000 - Date.now() - 10000;
    workerTimers.setTimeout(async () => {
      await refreshToken();
    }, timeout);
  }
  return response;
}

export async function logIn(authCode: string) {
  const response = await api.logIn(authCode);
  if (response.status == 200) {
    const timeout = response.data.at_exp * 1000 - Date.now() - 10000;
    workerTimers.setTimeout(async () => {
      await refreshToken();
    }, timeout);
  }
  return response;
}
