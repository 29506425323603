import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import "vue-nav-tabs/themes/vue-tabs.css";
// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import "primeflex/primeflex.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css";
import "vue-date-pick/dist/vueDatePick.css";

// import "@babel/polyfill";
// Import Component hooks before component definitions
import "./component-hooks";
import "./plugins/vee-validate";
import "./registerServiceWorker";

import NotificationClass from "@/components/Notification.vue";
import RequiredMark from "@/components/RequiredMark.vue";
import ValidatedInput from "@/components/ValidatedInput.vue";
import { googleClientId } from "@/env";
import store from "@/store";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import { Icon } from "@iconify/vue2";
import { TabsPlugin } from "bootstrap-vue";
import dayjs from "dayjs";
import PrimeVue from "primevue/config";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Chips from "primevue/chips";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import InlineMessage from "primevue/inlinemessage";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import MultiSelect from "primevue/multiselect";
import ScrollPanel from "primevue/scrollpanel";
import Sidebar from "primevue/sidebar";
import TabMenu from "primevue/tabmenu";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import Vue from "vue";
import Avatar from "vue-avatar";
import CountryFlag from "vue-country-flag";
import DatePick from "vue-date-pick";
import VueTabs from "vue-nav-tabs";
import VueSegmentAnalytics from "vue-segment-analytics";
import VueSidebarMenu from "vue-sidebar-menu";
import VueTelInput from "vue-tel-input";
import VueTour from "vue-tour";
import VuejsDialog from "vuejs-dialog";

import App from "./App.vue";
import router from "./router";

require("vue-tour/dist/vue-tour.css");

Vue.use(PrimeVue, { inputStyle: "filled" });

Vue.config.productionTip = false;

Vue.directive("tooltip", Tooltip);

Vue.use(VueTour);
Vue.use(VueNumberInput);
// Vue.use(ConfirmationService);
Vue.use(TabsPlugin);
Vue.use(VueSidebarMenu);
Vue.use(VueTabs);
Vue.use(ToastService);
const WRITE_TOKEN =
  process.env.NODE_ENV === "production"
    ? "uUYrA9IONpoapF2xquCFj9KkppxjzWv2"
    : "LMwCnFcIKUm4rWk9LDmTtmqAV5XPb0Iw";
Vue.use(VueSegmentAnalytics, { id: WRITE_TOKEN });
Vue.component("Icon", Icon);
Vue.component("Toast", Toast);
Vue.component("Sidebar", Sidebar);
Vue.component("InlineMessage", InlineMessage);
Vue.component("avatar", Avatar);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("ColumnGroup", ColumnGroup);
Vue.component("MultiSelect", MultiSelect);
Vue.component("InputText", InputText);
Vue.component("Dropdown", Dropdown);
Vue.component("Card", Card);
Vue.component("TabMenu", TabMenu);
Vue.component("Checkbox", Checkbox);
Vue.component("Button", Button);
Vue.component("Menu", Menu);
Vue.component("InputSwitch", InputSwitch);
Vue.component("Textarea", Textarea);
Vue.component("Dialog", Dialog);
Vue.component("Accordion", Accordion);
Vue.component("AccordionTab", AccordionTab);
Vue.component("Chips", Chips);
Vue.component("DatePick", DatePick);
Vue.component("RequiredMark", RequiredMark);
Vue.component("ValidatedInput", ValidatedInput);
Vue.component("Notification", NotificationClass);
Vue.component("TabView", TabView);
Vue.component("TabPanel", TabPanel);
Vue.component("ScrollPanel", ScrollPanel);
Vue.component("InputNumber", InputNumber);
Vue.component("Divider", Divider);

Vue.use(VuejsDialog);
Vue.use(VueTelInput);
Vue.component("country-flag", CountryFlag);
Vue.prototype.dayjs = dayjs;

Vue.prototype.$deleteOptions = {
  okText: "Yes",
  cancelText: "No",
  animation: "zoom", // Available: "zoom", "bounce", "fade"
  type: "hard", // coming soon: 'soft', 'hard'
  verification: "delete", // for hard confirm, user will be prompted to type this to enable the proceed button
  verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
  backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
};

Vue.prototype.$saveOptions = {
  okText: "Yes",
  cancelText: "No",
  animation: "zoom",
  backdropClose: false,
};

Vue.prototype.palette = [
  "#409eff",
  "#2d3142",
  "#4f5d75",
  "#707070",
  "#ef373e",
  "#fcbf49",
  "#fff3c2",
  "#67c23a",
  "#909399",
  "#c0c4cc",
];

Vue.filter(
  "percentage",
  function (value, decimals = 2, suffix: Boolean = true) {
    if (!value) {
      value = 0;
    }

    if (!decimals) {
      decimals = 0;
    }

    value = value * 100;
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    if (suffix) value = value + "%";
    return value;
  }
);

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
