<template>
  <b class="required-asterisk">  &#42;</b>
</template>

<script>
export default {
name: "RequiredMark"
}
</script>

<style scoped>
.required-asterisk {
  color: red;
  font-size: 1rem;
}
</style>
