import { api } from "@/api";
import { ActionContext } from "vuex";
import { State } from "../state";
import { ManagerState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { commitSetManager, commitUnsetManager } from "./mutations";

type MainContext = ActionContext<ManagerState, State>;

export const actions = {
  async actionUpdateManager(
    context: MainContext,
    payload: { id: number; data: object }
  ) {
    const response = await api.updateManager(payload.id, payload.data);
    if (response && response.status === 200) {
      commitSetManager(context, response.data);
      return response.data;
    }
  },
  async actionDeleteManager(context: MainContext, payload: { id: number }) {
    const response = await api.deleteManager(payload.id);
    commitUnsetManager(context);
    return response;
  },
  async actionClearManager(context: MainContext) {
    commitUnsetManager(context);
  },
  async actionCreateManager(context: MainContext, payload: { data: object }) {
    const response = await api.createManager(payload.data);
    if (response.status === 200) {
      commitSetManager(context, response.data);
      return response.data;
    } else {
      return null;
    }
  },
  async actionGetManager(context: MainContext, payload: { id: number }) {
    const response = await api.getManager(payload.id);
    commitSetManager(context, response.data);
  },
};

const { dispatch } = getStoreAccessors<ManagerState, State>("");

export const dispatchUpdateManager = dispatch(actions.actionUpdateManager);
export const dispatchDeleteManager = dispatch(actions.actionDeleteManager);
export const dispatchClearManager = dispatch(actions.actionClearManager);
export const dispatchCreateManager = dispatch(actions.actionCreateManager);
export const dispatchGetManager = dispatch(actions.actionGetManager);
