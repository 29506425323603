import * as localforage from "localforage";

import { Account, AccountFull, AccountFullData } from "@/interfaces";

import { apiUrl } from "@/env";
import axios from "axios";

export const accountsApi = {
  async listRecentAccounts(
    accountType: string,
    officeId: number = undefined,
    skip: number = 0,
    limit: number = 10
  ) {
    const params =
      officeId === undefined
        ? {
            account_type: accountType,
            skip,
            limit,
          }
        : {
            account_type: accountType,
            contract_office_id: officeId,
            skip,
            limit,
          };
    return axios.get(`${apiUrl}/api/accounts/list-recent`, { params });
  },

  async listAccountManagers(accountId: number) {
    const params = new URLSearchParams();
    params.append("account_id", accountId.toString());
    return axios.get(`${apiUrl}/api/accounts/list-managers`, { params });
  },

  async listAccountPreviousManagers(accountId: number) {
    const params = new URLSearchParams();
    params.append("account_id", accountId.toString());
    return axios.get(`${apiUrl}/api/accounts/list-previous-managers`, {
      params,
    });
  },

  async updateAccountManagers(accountId: number, payload) {
    const params = new URLSearchParams();
    params.append("account_id", accountId.toString());
    return axios.put(`${apiUrl}/api/accounts/update-managers`, payload, {
      params,
    });
  },

  async listAccountsByManager(managerId: number) {
    const params = new URLSearchParams();
    params.append("manager_id", managerId.toString());
    return axios.get(`${apiUrl}/api/accounts/list-by-manager`, {
      params,
    });
  },

  async requestDeletion(accountId: number, reason: string) {
    const payload = {
      account_id: accountId.toString(),
      reason: reason.toString(),
    };
    return axios.post(`${apiUrl}/api/accounts/request-delete`, payload);
  },

  async listAccountsFull(byPassCache = false) {
    const params = new URLSearchParams();
    let storedAccountsFull: AccountFull[];

    if (!byPassCache) {
      const currentMd5: string = await localforage.getItem("accounts-full-md5");
      storedAccountsFull = await localforage.getItem("accounts-full");
      if (currentMd5 && storedAccountsFull) {
        params.append("cached_md5", currentMd5);
      }
    }
    const response = await axios.get<AccountFullData>(
      `${apiUrl}/api/accounts/list-all-data`,
      { params }
    );
    if (response.data) {
      if (response.data.status === "updated" || !storedAccountsFull) {
        await localforage.setItem("accounts-full-md5", response.data.md5);
        await localforage.setItem("accounts-full", response.data.data);
        return response.data;
      } else {
        return { data: storedAccountsFull };
      }
    }
  },

  async getAccountFull(id: number) {
    const params = new URLSearchParams();
    params.append("account_id", id.toString());
    return axios.get<AccountFull>(`${apiUrl}/api/accounts/get-all-data`, {
      params,
    });
  },

  async listAccountsByCreator(creatorId: number) {
    const params = new URLSearchParams();
    params.append("creator_id", creatorId.toString());
    return axios.get<AccountFull[]>(`${apiUrl}/api/accounts/list-by-creator`, {
      params,
    });
  },

  async updateAccount(accountId: number, updateData: object) {
    const params = new URLSearchParams();
    params.append("account_id", accountId.toString());
    return axios.put<Account>(`${apiUrl}/api/accounts/update`, updateData, {
      params,
    });
  },

  async deleteAccount(accountId: number) {
    const params = new URLSearchParams();
    params.append("account_id", accountId.toString());
    return axios.delete<Account>(`${apiUrl}/api/accounts/delete`, { params });
  },

  async createAccount(createData: object) {
    return axios.post<Account>(`${apiUrl}/api/accounts/create`, createData);
  },

  async getYoutubeChannelDetails(channelId: string) {
    return axios.get(`${apiUrl}/api/utils/youtube-channels/${channelId}/`);
  },

  async getDashboardUser(userId: number) {
    return axios.get(`${apiUrl}/api/dashboard-users/${userId}/`);
  },

  async importAccounts(accountType: string, file: File) {
    const formData = new FormData();
    formData.append("account_type", accountType);
    formData.append("file", file);
    return axios.post(`${apiUrl}/api/accounts/import`, formData);
  },
};
