import { Creator, MappingGroup } from "@/interfaces";
import { MappingGroupState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { mappingGroupState } from "@/store/mapping-group/index";

export const mutations = {
  setMappingGroup(state: MappingGroupState, payload: MappingGroup) {
    state.mappingGroup = payload;
  },
  unsetMappingGroup(state: MappingGroupState) {
    state.mappingGroup = { ...mappingGroupState.mappingGroup };
  },
  setCreatorsByMappingGroup(state: MappingGroupState, payload: Creator[]) {
    state.creatorsByMappingGroup = payload;
  },
};

const { commit } = getStoreAccessors<MappingGroupState, State>("");

export const commitSetMappingGroup = commit(mutations.setMappingGroup);
export const commitSetCreatorsByMappingGroup = commit(
  mutations.setCreatorsByMappingGroup
);
export const commitUnsetMappingGroup = commit(mutations.unsetMappingGroup);
