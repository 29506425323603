import { firstApi } from "@/apis";
import { accountsApi } from "@/apis/accounts";
import { authApi } from "@/apis/auth";
import { choicesApi } from "@/apis/classes";
import { commentsApi } from "@/apis/comments";
import { creatorsApi } from "@/apis/creators";
import managersApi from "@/apis/managers";
import { mappingGroupsApi } from "@/apis/mapping-groups";
import { notificationsApi } from "@/apis/notifications";
export const api = {
  ...firstApi,
  ...managersApi,
  ...mappingGroupsApi,
  ...creatorsApi,
  ...choicesApi,
  ...commentsApi,
  ...notificationsApi,
  ...accountsApi,
  ...authApi,
};
