import { api } from "@/api";
import { ActionContext } from "vuex";
import { State } from "../state";
import { AccountState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { commitSetAccountFull, commitSetSearchCreatorFuse } from "./mutations";
import Fuse from "fuse.js";

type MainContext = ActionContext<AccountState, State>;

export const actions = {
  async actionGetAccountFull(context: MainContext, payload: { id: number }) {
    const response = await api.getAccountFull(payload.id);
    if (response && response.status === 200) {
      commitSetAccountFull(context, response.data);
    }
  },
  async actionGetSearchCreatorFuse(context: MainContext) {
    const fuseOptions = {
      threshold: 0.4,
      findAllMatches: true,
      keys: ["full_name", "email", "id"],
    };
    const response = await api.listSearchCreators();
    if (response) {
      const creatorFuse = new Fuse(response.data, fuseOptions);
      commitSetSearchCreatorFuse(context, creatorFuse);
    }
  },
  async actionUpdateAccount(
    context: MainContext,
    payload: { id: number; data: object }
  ) {
    const response = await api.updateAccount(payload.id, payload.data);
    if (response && response.status === 200) {
      commitSetAccountFull(context, response.data);
      return response.data;
    }
  },
  async actionCreateAccount(context: MainContext, payload: { data: object }) {
    const response = await api.createAccount(payload.data);
    if (response && response.status === 200) {
      commitSetAccountFull(context, response.data);
      return response.data;
    }
  },
  async actionDeleteAccount(context: MainContext, payload: { id: number }) {
    const response = await api.deleteAccount(payload.id);
    return response;
  },
};

const { dispatch } = getStoreAccessors<AccountState, State>("");

export const dispatchGetAccountFull = dispatch(actions.actionGetAccountFull);
export const dispatchGetSearchCreatorFuse = dispatch(
  actions.actionGetSearchCreatorFuse
);
export const dispatchUpdateAccount = dispatch(actions.actionUpdateAccount);
export const dispatchCreateAccount = dispatch(actions.actionCreateAccount);
export const dispatchDeleteAccount = dispatch(actions.actionDeleteAccount);
