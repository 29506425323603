import { AuthState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  isMe: (state: AuthState) => {
    return state.me.scopes && state.me.scopes.includes("me");
  },
  isUser: (state: AuthState) => {
    return state.me.scopes && state.me.scopes.includes("user");
  },
  isManager: (state: AuthState) => {
    return state.me.scopes && state.me.scopes.includes("manager");
  },
  isSuper: (state: AuthState) => {
    return state.me.scopes && state.me.scopes.includes("super");
  },
  isAlpha: (state: AuthState) => {
    return state.me.scopes && state.me.scopes.includes("alpha");
  },
  maxScope: (state: AuthState) => {
    if (!state.me.scopes) return null;
    if (state.me.scopes.includes("alpha")) {
      return "alpha";
    } else if (state.me.scopes.includes("super")) {
      return "super";
    } else if (state.me.scopes.includes("manager")) {
      return "manager";
    } else if (state.me.scopes.includes("user")) {
      return "user";
    } else if (state.me.scopes.includes("me")) {
      return "me";
    }
    return null;
  },
  me: (state: AuthState) => state.me,
};

const { read } = getStoreAccessors<AuthState, State>("");

export const readIsMe = read(getters.isMe);
export const readIsUser = read(getters.isUser);
export const readIsManager = read(getters.isManager);
export const readIsSuper = read(getters.isSuper);
export const readIsAlpha = read(getters.isAlpha);
export const readMaxScope = read(getters.maxScope);
export const readMe = read(getters.me);
