import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AuthState } from "./state";

const authState: AuthState = {
  me: null,
};

export const authModule = {
  state: authState,
  mutations,
  actions,
  getters,
};
