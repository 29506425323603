import { MappingGroup } from "@/interfaces";
import { apiUrl } from "@/env";
import axios from "axios";

export const mappingGroupsApi = {
  async getMappingGroup(managerGroupId: number) {
    const params = new URLSearchParams();
    params.append("mapping_group_id", managerGroupId.toString());
    return axios.get<MappingGroup>(`${apiUrl}/api/mapping_groups/get`, {
      params
    });
  },
  async updateMappingGroup(mappingGroupId: number, updateData: object) {
    const params = new URLSearchParams();
    params.append("mapping_group_id", mappingGroupId.toString());
    return axios.put<MappingGroup>(
      `${apiUrl}/api/mapping_groups/update`,
      updateData,
      { params }
    );
  },
  async deleteMappingGroup(mappingGroupId: number) {
    const params = new URLSearchParams();
    params.append("mapping_group_id", mappingGroupId.toString());
    return axios.delete<MappingGroup>(`${apiUrl}/api/mapping_groups/delete`, {
      params
    });
  },
  async createMappingGroup(createData: object) {
    return axios.post<MappingGroup>(
      `${apiUrl}/api/mapping_groups/create`,
      createData
    );
  },
  async listMappingGroups() {
    const params = new URLSearchParams();
    const perPage = 0;
    const skip = 0;
    params.append("skip", skip.toString());
    params.append("limit", perPage.toString());
    return axios.get<MappingGroup[]>(`${apiUrl}/api/mapping_groups/list`, {
      params
    });
  },
  async listMappingGroupsByManager(managerId: number) {
    const params = new URLSearchParams();
    params.append("manager_id", managerId.toString());
    params.append("skip", "0");
    params.append("limit", "0");
    return axios.get<MappingGroup[]>(
      `${apiUrl}/api/mapping_groups/list-by-manager`,
      { params }
    );
  }
};
