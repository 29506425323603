import { Creator, Manager, MappingGroup } from "@/interfaces";
import { ManagerState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { managerState } from "@/store/manager/index";

export const mutations = {
  setManager(state: ManagerState, payload: Manager) {
    state.manager = payload;
  },
  unsetManager(state: ManagerState) {
    state.manager = { ...managerState.manager };
  },
  setMappingGroupsByManager(state: ManagerState, payload: MappingGroup[]) {
    state.mappingGroupsByManager = payload;
  },
  setCreatorsByManager(state: ManagerState, payload: Creator[]) {
    state.creatorsByManager = payload;
  },
};

const { commit } = getStoreAccessors<ManagerState, State>("");

export const commitSetManager = commit(mutations.setManager);
export const commitUnsetManager = commit(mutations.unsetManager);
export const commitSetMappingGroupsByManager = commit(
  mutations.setMappingGroupsByManager
);
export const commitSetCreatorsByManager = commit(
  mutations.setCreatorsByManager
);
