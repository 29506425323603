import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { ManagerState } from "./state";

export const managerState: ManagerState = {
  manager: {
    active: true,
    last_name: "",
    first_name: "",
    office_id: null,
    pl_office_id: null,
    id: null,
  },
  mappingGroupsByManager: [],
  creatorsByManager: [],
};

export const managerModule = {
  state: managerState,
  mutations,
  actions,
  getters,
};
