import { Me } from "@/interfaces";
import { AuthState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setMe(state: AuthState, payload: Me) {
    state.me = payload;
  },
};

const { commit } = getStoreAccessors<AuthState | any, State>("");

export const commitSetMe = commit(mutations.setMe);
