import { api } from "@/api";
import { ActionContext } from "vuex";
import { State } from "../state";
import { CreatorState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetAccounts,
  commitSetCreator,
  commitSetMappingGroupId,
  commitUnsetCreator,
} from "./mutations";
import { readCreator } from "@/store/creator/getters";

type MainContext = ActionContext<CreatorState, State>;

export const actions = {
  async actionUpdateCreator(
    context: MainContext,
    payload: { id: number; data: object }
  ) {
    const response = await api.updateCreator(payload.id, payload.data);
    if (response && response.status === 200) {
      commitSetCreator(context, response.data);
      return response.data;
    }
  },
  async actionDeleteCreator(context: MainContext, payload: { id: number }) {
    const response = await api.deleteCreator(payload.id);
    if (response) {
      commitUnsetCreator(context);
    }
    return response;
  },
  async actionClearCreator(context: MainContext) {
    await commitUnsetCreator(context);
  },
  async actionCreateCreator(context: MainContext, payload: { data: object }) {
    const response = await api.createCreator(payload.data);
    if (response.status === 200) {
      commitSetCreator(context, response.data);
      return response.data;
    }
  },
  async actionGetCreator(context: MainContext, payload: { id: number }) {
    const response = await api.getCreator(payload.id);
    if (response) {
      const creator = response.data;
      commitSetCreator(context, creator);
    }
  },
  actionGetMappingGroupId(context: MainContext) {
    const creator = readCreator(context);
    if (creator) {
      commitSetMappingGroupId(context, creator.mapping_group_id);
    }
  },
  async actionListAccounts(context: MainContext, payload: { id: number }) {
    const response = await api.listAccountsByCreator(payload.id);
    let toCommit;
    if (response) {
      toCommit = response.data;
    } else {
      toCommit = [];
    }
    commitSetAccounts(context, toCommit);
  },
};

const { dispatch } = getStoreAccessors<CreatorState, State>("");

export const dispatchGetCreator = dispatch(actions.actionGetCreator);
export const dispatchGetMappingGroupId = dispatch(
  actions.actionGetMappingGroupId
);
export const dispatchClearCreator = dispatch(actions.actionClearCreator);
export const dispatchCreateCreator = dispatch(actions.actionCreateCreator);
export const dispatchUpdateCreator = dispatch(actions.actionUpdateCreator);
export const dispatchDeleteCreator = dispatch(actions.actionDeleteCreator);
export const dispatchListAccounts = dispatch(actions.actionListAccounts);
