




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ValidatedInput extends Vue {
  @Prop({ default: "" }) public readonly rules: any;
  @Prop({ default: null }) public readonly label: string;
  @Prop({ default: null }) public readonly name?: string;
  @Prop({ default: false }) public readonly isNew?: boolean;
  @Prop({ default: true }) public readonly errorDisplay?: boolean;

  get required() {
    if (this.rules instanceof String) {
      return this.rules.includes("required");
    } else if (this.rules.hasOwnProperty("required")) {
      return this.rules.required;
    } else {
      return false;
    }
  }
}
