import { AccountFull, Creator, YoutubeChannel } from "@/interfaces";
import { AccountState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { accountState } from "@/store/account/index";

export const mutations = {
  setAccountFull(state: AccountState, payload: AccountFull) {
    state.accountFull = payload;
  },
  setSearchCreatorFuse(state: AccountState, payload: any) {
    state.searchCreatorFuse = payload;
  },
};

const { commit } = getStoreAccessors<AccountState, State>("");

export const commitSetAccountFull = commit(mutations.setAccountFull);
export const commitSetSearchCreatorFuse = commit(
  mutations.setSearchCreatorFuse
);
