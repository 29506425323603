import {
  AccountTeam,
  Category,
  ChoiceModel,
  Country,
  Creator,
  Language,
  Manager,
  MappingGroup,
  Office,
  Team,
  AppNotification,
  PodcastGenre,
} from "@/interfaces";
import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setNotificationSidebarVisible(state: MainState, payload: boolean) {
    state.notificationSidebarVisible = payload;
  },
  setNavigationVisible(state: MainState, payload: boolean) {
    state.navigationVisible = payload;
  },
  setManagers(state: MainState, payload: Manager[]) {
    state.managers = payload;
  },
  setMappingGroups(state: MainState, payload: MappingGroup[]) {
    state.mappingGroups = payload;
  },
  setCreators(state: MainState, payload: Creator[]) {
    state.creators = payload;
  },
  setOffices(state: MainState, payload: Office[]) {
    state.offices = payload;
  },
  setAccountTeams(state: MainState, payload: AccountTeam[]) {
    state.accountTeams = payload;
  },
  setCountries(state: MainState, payload: Country[]) {
    state.countries = payload;
  },
  setLanguages(state: MainState, payload: Language[]) {
    state.languages = payload;
  },
  setTeams(state: MainState, payload: Team[]) {
    state.teams = payload;
  },
  setCrews(state: MainState, payload: ChoiceModel[]) {
    state.crews = payload;
  },
  setTiers(state: MainState, payload: ChoiceModel[]) {
    state.tiers = payload;
  },
  setClasses(state: MainState, payload: ChoiceModel[]) {
    state.classes = payload;
  },
  setNetworks(state: MainState, payload: ChoiceModel[]) {
    state.networks = payload;
  },
  setNetworkStatuses(state: MainState, payload: ChoiceModel[]) {
    state.networkStatuses = payload;
  },
  setChinaPlatformNetworkStatuses(state: MainState, payload: ChoiceModel[]) {
    state.chinaPlatformNetworkStatuses = payload;
  },
  setContentFormats(state: MainState, payload: ChoiceModel[]) {
    state.contentFormats = payload;
  },
  setContentSources(state: MainState, payload: ChoiceModel[]) {
    state.contentSources = payload;
  },
  setCategories(state: MainState, payload: Category[]) {
    state.categories = payload;
  },
  setRights(state: MainState, payload: ChoiceModel[]) {
    state.rights = payload;
  },
  setPodcastStatuses(state: MainState, payload: ChoiceModel[]) {
    state.podcastStatuses = payload;
  },
  setPodcastGenres(state: MainState, payload: PodcastGenre[]) {
    state.podcastGenres = payload;
  },
  setManagerRoles(state: MainState, payload: ChoiceModel[]) {
    state.managerRoles = payload;
  },
  setContractStatuses(state: MainState, payload: ChoiceModel[]) {
    state.contractStatuses = payload;
  },
  setSearchObjects(state: MainState, payload: any[]) {
    state.searchObjects = payload;
  },
  setGeneralFuse(state: MainState, payload: any) {
    state.generalFuse = payload;
  },
  setUserNotifications(state: MainState, payload: AppNotification[]) {
    state.userNotifications = payload;
  },
  setSystemNotifications(state: MainState, payload: AppNotification[]) {
    state.systemNotifications = payload;
  },
  setUserUnread(state: MainState, payload: number) {
    state.userUnread = payload;
  },
  setSystemUnread(state: MainState, payload: number) {
    state.systemUnread = payload;
  },
};

const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetNotificationSidebarVisible = commit(
  mutations.setNotificationSidebarVisible
);
export const commitSetNavigationVisible = commit(
  mutations.setNavigationVisible
);
export const commitSetUserNotifications = commit(
  mutations.setUserNotifications
);
export const commitSetSystemNotifications = commit(
  mutations.setSystemNotifications
);
export const commitSetUserUnread = commit(mutations.setUserUnread);
export const commitSetSystemUnread = commit(mutations.setSystemUnread);
export const commitSetManagers = commit(mutations.setManagers);
export const commitSetMappingGroups = commit(mutations.setMappingGroups);
export const commitSetCreators = commit(mutations.setCreators);
export const commitSetOffices = commit(mutations.setOffices);
export const commitSetAccountTeams = commit(mutations.setAccountTeams);
export const commitSetCountries = commit(mutations.setCountries);
export const commitSetTeams = commit(mutations.setTeams);
export const commitSetManagerRoles = commit(mutations.setManagerRoles);
export const commitSetContractStatuses = commit(mutations.setContractStatuses);
export const commitSetLanguages = commit(mutations.setLanguages);
export const commitSetCrews = commit(mutations.setCrews);
export const commitSetSearchObjects = commit(mutations.setSearchObjects);
export const commitSetGeneralFuse = commit(mutations.setGeneralFuse);
export const commitSetTiers = commit(mutations.setTiers);
export const commitSetClasses = commit(mutations.setClasses);
export const commitSetNetworks = commit(mutations.setNetworks);
export const commitSetNetworkStatuses = commit(mutations.setNetworkStatuses);
export const commitSetChinaPlatformNetworkStatuses = commit(
  mutations.setChinaPlatformNetworkStatuses
);
export const commitSetCategories = commit(mutations.setCategories);
export const commitSetRights = commit(mutations.setRights);
export const commitSetPodcastStatuses = commit(mutations.setPodcastStatuses);
export const commitSetPodcastGenres = commit(mutations.setPodcastGenres);
export const commitSetContentFormats = commit(mutations.setContentFormats);
export const commitSetContentSources = commit(mutations.setContentSources);
