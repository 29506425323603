import Vuex, { StoreOptions } from "vuex";

import { State } from "./state";
import Vue from "vue";
import { accountModule } from "@/store/account";
import { authModule } from "@/store/auth";
import { creatorModule } from "@/store/creator";
import { mainModule } from "./main";
import { managerModule } from "./manager";
import { mappingGroupModule } from "@/store/mapping-group";

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    auth: authModule,
    manager: managerModule,
    mappingGroup: mappingGroupModule,
    creator: creatorModule,
    account: accountModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
