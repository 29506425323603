import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  notificationSidebarVisible: (state: MainState) =>
    state.notificationSidebarVisible,
  navigationVisible: (state: MainState) => state.navigationVisible,
  managers: (state: MainState) => state.managers,
  mappingGroups: (state: MainState) => state.mappingGroups,
  creators: (state: MainState) => state.creators,
  offices: (state: MainState) => state.offices,
  accountTeams: (state: MainState) => state.accountTeams,
  countries: (state: MainState) => state.countries,
  languages: (state: MainState) => state.languages,
  teams: (state: MainState) => state.teams,
  crews: (state: MainState) => state.crews,
  tiers: (state: MainState) => state.tiers,
  classes: (state: MainState) => state.classes,
  managerRoles: (state: MainState) => state.managerRoles,
  contractStatuses: (state: MainState) => state.contractStatuses,
  searchObjects: (state: MainState) => state.searchObjects,
  generalFuse: (state: MainState) => state.generalFuse,
  networks: (state: MainState) => state.networks,
  networkStatuses: (state: MainState) => state.networkStatuses,
  chinaPlatformNetworkStatuses: (state: MainState) =>
    state.chinaPlatformNetworkStatuses,
  rights: (state: MainState) => state.rights,
  categories: (state: MainState) => state.categories,
  contentFormats: (state: MainState) => state.contentFormats,
  contentSources: (state: MainState) => state.contentSources,
  podcastStatuses: (state: MainState) => state.podcastStatuses,
  podcastGenres: (state: MainState) => state.podcastGenres,
  userNotifications: (state: MainState) => state.userNotifications,
  systemNotifications: (state: MainState) => state.systemNotifications,
  userUnread: (state: MainState) => state.userUnread,
  systemUnread: (state: MainState) => state.systemUnread,
};

const { read } = getStoreAccessors<MainState, State>("");

export const readNotificationSidebarVisible = read(
  getters.notificationSidebarVisible
);
export const readNavigationVisible = read(getters.navigationVisible);
export const readUserNotifications = read(getters.userNotifications);
export const readSystemNotifications = read(getters.systemNotifications);
export const readUserUnread = read(getters.userUnread);
export const readSystemUnread = read(getters.systemUnread);
export const readManagers = read(getters.managers);
export const readMappingGroups = read(getters.mappingGroups);
export const readCreators = read(getters.creators);
export const readOffices = read(getters.offices);
export const readAccountTeams = read(getters.accountTeams);
export const readCountries = read(getters.countries);
export const readTeams = read(getters.teams);
export const readManagerRoles = read(getters.managerRoles);
export const readContractStatuses = read(getters.contractStatuses);
export const readLanguages = read(getters.languages);
export const readCrews = read(getters.crews);
export const readSearchObjects = read(getters.searchObjects);
export const readGeneralFuse = read(getters.generalFuse);
export const readTiers = read(getters.tiers);
export const readClasses = read(getters.classes);
export const readRights = read(getters.rights);
export const readNetworks = read(getters.networks);
export const readNetworkStatuses = read(getters.networkStatuses);
export const readChinaPlatformNetworkStatuses = read(
  getters.chinaPlatformNetworkStatuses
);
export const readCategories = read(getters.categories);
export const readContentFormats = read(getters.contentFormats);
export const readContentSources = read(getters.contentSources);
export const readPodcastStatuses = read(getters.podcastStatuses);
export const readPodcastGenres = read(getters.podcastGenres);
